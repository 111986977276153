<template>
  <div :class="b()">
    <div v-show="showResult">
      <div class="doctor-card">
        <doctor-card-navigation :id="id" />
        <div class="doctor-card-wrapper">
          <doctor-card-info></doctor-card-info>
          <router-view></router-view>
        </div>
      </div>
    </div>
    <div v-show="showNotResult" class="no-found">
      <div class="d-flex justify-center align-center">
        <span> Доктор не найден </span>
      </div>
    </div>
  </div>
</template>

<script>
import DoctorCardNavigation from "components/doctor-card/DoctorCardNavigation";
import DoctorCardInfo from "components/doctor-card/DoctorCardInfo";
import { mapActions, mapGetters } from "vuex";
import * as names from "store/names";
import { isEmpty } from "lodash";

export default {
  name: "DoctorCardComponent",
  components: { DoctorCardNavigation, DoctorCardInfo },
  props: ["id"],
  data: () => ({}),
  created() {
    this.saveDoctorId(this.id);
  },
  mounted() {},
  computed: {
    ...mapGetters({
      objDoctor: names.OBJ_DOCTOR_CARD,
    }),

    apiAddress() {
      return names.API_ADDRESS;
    },

    showResult() {
      return !isEmpty(this.objDoctor);
    },
    showNotResult() {
      return isEmpty(this.objDoctor);
    },
  },
  methods: {
    ...mapActions({
      saveDoctorId: names.ACTION_SAVE_DOCTOR_ID,
    }),
  },
};
</script>

<style scoped lang="scss">
@import "~scss/helpers/helpers";

.DoctorCardComponent {
  min-height: 300px;
  margin-bottom: 70px;
  .no-found {
    height: 100%;
    margin-top: 60px;
  }
}

.doctor-card {
  font-size: 14px;
  line-height: 17px;
  max-width: 930px;
  margin: auto;

  .doctor-card-wrapper {
    border: 10px solid #e3ecfc;
    font-family: $sanspro;
  }
}
</style>
