<template>
  <div>
    <div class="doctor-card-info">
      <v-row>
        <v-col cols="12" sm="3" class="d-flex justify-center justify-sm-start">
          <div class="card-info__image">
            <img :src="objDoctor.avatar" alt="doc_photo" />
          </div>
        </v-col>
        <v-col cols="12" sm="9">
          <div class="card-info__info">
            <h1 class="card-info__name">{{ objDoctor.name }}</h1>
            <div class="card-info__specialization pb-3">
              <span>
                {{ objDoctor.doctorSpecializationString }}
              </span>
            </div>
            <div
              v-if="objDoctor.doctorCategory || objDoctor.doctorDegree"
              class="card-info__category-degree"
            >
              {{
                fullCategory(objDoctor.doctorCategory, objDoctor.doctorDegree)
              }}
            </div>
            <div class="card-info__clinics mb-3">
              <h3 class="pb-2 pt-2">Врач принимает в следующих клиниках:</h3>
              <ul class="card-info__list">
                <li
                  v-for="(item, index) in objDoctor.doctorClinics"
                  :key="index"
                >
                  <a :href="'/department/' + item.id" target="_blank">
                    {{ item.name }}
                  </a>
                </li>
              </ul>
            </div>
            <div class="justify-center justify-sm-start d-flex">
              <v-btn
                outlined
                color="success"
                class="btn-curtain-open"
                @click="openDrawerFast(objDoctor)"
              >
                Записаться на прием
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import * as names from "store/names";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      respData: {
        id: 1,
        entity: "card",
      },
    };
  },

  computed: {
    fullCategory() {
      return function (category, degree) {
        let fullCat = "";

        if (category && !degree) {
          fullCat = category.name;
        }
        if (category && degree) {
          fullCat = category.name + ", " + degree.name;
        }
        if (!category && degree) {
          fullCat = degree.name;
        }

        return fullCat;
      };
    },
    apiAddress() {
      return names.API_ADDRESS;
    },
    ...mapGetters({
      objDoctor: names.OBJ_DOCTOR_CARD,
      docId: names.DOCTOR_ID,
      selectedCity: names.OBJ_SELECTED_CITY,
    }),
  },

  mounted() {
    this.getDoctorInfo({ doctor_id: this.docId });
  },
  methods: {
    ...mapActions({
      getDoctorInfo: names.ACTION_SAVE_OBJ_DOCTOR_CARD,
      selectResultTemplate: names.ACTION_SELECT_RESULT_TEMPLATE,
      selectedDoctorInfo: names.ACTION_SAVE_SELECTED_DOCTOR_INFO,
    }),
    async getResult() {
      await this.selectResultTemplate({
        templateName: `DoctorCard`,
        data: this.respData,
      });
    },
    saveSelectDoctorInfo(obj) {
      this.selectedDoctorInfo({
        doctorId: obj.id,
        doctorName: obj.name,
        cityId: this.selectedCity.id,
        childDoc: obj.childrens_md,
      });
    },
    openDrawerFast(obj) {
      this.getResult();
      this.saveSelectDoctorInfo(obj);
      document.querySelector("body").style.overflow = "hidden";
      this.$drawer.showDrawer(true, "Fast");
    },
  },
};
</script>

<style scoped lang="scss">
@import "~scss/helpers/helpers";

ul {
  padding-left: 0;

  li {
    list-style-type: none;
    padding-bottom: 10px;
  }
}

.btn-curtain-open {
  width: 220px;
  height: 40px;
  font-size: 16px;
  letter-spacing: 0.04em;
  text-transform: none;
}

.doctor-card-info {
  min-height: 300px;
  padding: 20px;

  .card-info {
    &__image {
      max-width: 150px;
      height: 150px;
      overflow-y: hidden;

      img {
        width: 100%;
        height: auto;
        -o-object-fit: contain;
        object-fit: contain;
      }
    }

    &__specialization {
      font-size: 16px;
      color: $grey65;
    }

    &__list {
      li {
        a {
          color: $grey65;
          text-decoration: none;

          &:hover {
            color: $blue-color;
            text-decoration: underline;
          }
        }
      }
    }

    &__name {
      font-weight: bold;
      font-size: 1.7rem !important;
      line-height: 29px;
      width: 100%;
      margin-bottom: 12px;
    }

    &__category {
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      color: #009eff;
      margin-bottom: 17px;
    }

    &__rating {
      display: inline-block;
      margin-top: 10px;

      span {
        margin-left: 8px;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        color: $primary-grey-color;
      }
    }
  }
}

@include phone-only {
  .doctor-card__info {
    margin: 0px;
    padding: 10px;
  }
}

@include desktop-down {
  .doctor-card-info__image {
    margin: 5px;
  }
}
</style>
